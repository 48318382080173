import React from 'react';

interface Props {
  className?: string;
  type?: 'sunrise' | 'sunset';
}

const SunlightTimes: React.FC<Props> = ({ className, type = 'sunrise' }) =>
  type === 'sunrise' ? (
    <svg
      className={className}
      data-testid="sunlight-times-sunrise"
      fill="none"
      height="18"
      viewBox="0 0 24 18"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M12 8.80505C11.5898 8.80505 11.2573 8.4875 11.2573 8.09579L11.2573 2.71543L9.55434 4.34171C9.2643 4.61869 8.79405 4.61869 8.504 4.34171C8.21396 4.06472 8.21396 3.61564 8.504 3.33865L11.487 0.4899C11.7741 0.215791 12.2259 0.215791 12.5129 0.4899L15.496 3.33865C15.786 3.61564 15.786 4.06472 15.496 4.34171C15.2059 4.61869 14.7357 4.61869 14.4456 4.34171L12.7427 2.71543L12.7427 8.09579C12.7427 8.4875 12.4102 8.80505 12 8.80505Z"
        fill="#171717"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M3.51476 9.01585C3.22187 8.73886 3.22187 8.28978 3.51476 8.01279C3.80765 7.73581 4.28253 7.73581 4.57542 8.01279L6.69674 10.0189C6.98963 10.2959 6.98963 10.745 6.69674 11.022C6.40385 11.2989 5.92897 11.2989 5.63608 11.022L3.51476 9.01585ZM19.4248 8.0128C19.7176 7.73581 20.1925 7.73581 20.4854 8.0128C20.7783 8.28978 20.7783 8.73887 20.4854 9.01585L18.3641 11.022C18.0712 11.299 17.5963 11.299 17.3034 11.022C17.0105 10.745 17.0105 10.2959 17.3034 10.0189L19.4248 8.0128ZM23.2908 15.328C23.6825 15.328 24.0001 15.6456 24.0001 16.0373C24.0001 16.429 23.6825 16.7466 23.2908 16.7466H20.2093C19.8176 16.7466 19.5001 16.429 19.5001 16.0373C19.5001 15.6456 19.8176 15.328 20.2093 15.328H23.2908ZM0 16.0372C0 16.4289 0.31755 16.7465 0.709268 16.7465H3.79073C4.18245 16.7465 4.5 16.4289 4.5 16.0372C4.5 15.6455 4.18245 15.3279 3.79073 15.3279H0.709268C0.31755 15.3279 0 15.6455 0 16.0372ZM8.6141 16.0371C8.6141 14.3599 10.0706 12.8956 12 12.8956C13.9295 12.8956 15.386 14.3599 15.386 16.0371C15.386 16.6524 15.8848 17.1512 16.5 17.1512C17.1153 17.1512 17.6141 16.6524 17.6141 16.0371C17.6141 13.0138 15.0412 10.6675 12 10.6675C8.95892 10.6675 6.38599 13.0138 6.38599 16.0371C6.38599 16.6524 6.88477 17.1512 7.50004 17.1512C8.11532 17.1512 8.6141 16.6524 8.6141 16.0371Z"
        fill="#6BA2BB"
        fillRule="evenodd"
      />
    </svg>
  ) : (
    <svg
      className={className}
      data-testid="sunlight-times-sunset"
      fill="none"
      height="18"
      viewBox="0 0 24 18"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12 0C12.4142 0 12.75 0.32067 12.75 0.716236V6.14945L14.4697 4.5072C14.7626 4.22749 15.2374 4.22749 15.5303 4.5072C15.8232 4.78691 15.8232 5.2404 15.5303 5.52011L12.518 8.39685C12.2281 8.67365 11.7719 8.67365 11.482 8.39685L8.46967 5.52011C8.17678 5.2404 8.17678 4.78691 8.46967 4.5072C8.76256 4.22749 9.23744 4.22749 9.53033 4.5072L11.25 6.14945V0.716236C11.25 0.32067 11.5858 0 12 0Z"
        fill="#171717"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.4247 8.10126C19.7176 7.82155 20.1925 7.82155 20.4853 8.10126C20.7782 8.38097 20.7782 8.83446 20.4853 9.11417L18.364 11.14C18.0711 11.4197 17.5963 11.4197 17.3034 11.14C17.0105 10.8603 17.0105 10.4068 17.3034 10.1271L19.4247 8.10126ZM23.2838 15.4883C23.6793 15.4883 24 15.809 24 16.2045C24 16.6001 23.6793 16.9208 23.2838 16.9208H20.2162C19.8207 16.9208 19.5 16.6001 19.5 16.2045C19.5 15.809 19.8207 15.4883 20.2162 15.4883H23.2838ZM0 16.2045C0 16.6001 0.32067 16.9207 0.716236 16.9207H3.78376C4.17933 16.9207 4.5 16.6001 4.5 16.2045C4.5 15.8089 4.17933 15.4883 3.78376 15.4883H0.716236C0.32067 15.4883 0 15.8089 0 16.2045ZM3.51477 9.1142C3.22187 8.83449 3.22187 8.381 3.51477 8.10129C3.80766 7.82158 4.28253 7.82158 4.57543 8.10129L6.69675 10.1271C6.98964 10.4068 6.98964 10.8603 6.69675 11.14C6.40385 11.4197 5.92898 11.4197 5.63609 11.14L3.51477 9.1142ZM8.625 16.2045C8.625 14.5007 10.0866 13.0321 12 13.0321C13.9134 13.0321 15.375 14.5007 15.375 16.2045C15.375 16.8259 15.8787 17.3295 16.5 17.3295C17.1213 17.3295 17.625 16.8259 17.625 16.2045C17.625 13.1615 15.0572 10.7821 12 10.7821C8.9428 10.7821 6.375 13.1615 6.375 16.2045C6.375 16.8259 6.87868 17.3295 7.5 17.3295C8.12132 17.3295 8.625 16.8259 8.625 16.2045Z"
        fill="#6BA2BB"
      />
    </svg>
  );

export default SunlightTimes;
